.landingPage {
  .heroSection {
    background-image: url("../assets/heroBackground.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &::after {
      content: "";
      position: absolute;
      border-radius: 722px;
      width: 722px;
      height: 722px;
      background: linear-gradient(
        325deg,
        #f4b400 33.75%,
        rgba(244, 180, 0, 0) 105.97%
      );
      right: -15%;
      bottom: -20%;
    }

    .topShape {
      position: absolute;
      top: -50%;
      left: -20%;
      mix-blend-mode: overlay;
    }
  }

  .instructorSection {
    background-image: url("../assets/heroBackground.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    .stageCard {
      height: 207px;
      border-radius: 20px;
      background-blend-mode: soft-light, normal;
      mix-blend-mode: hard-light;
      position: relative;
      background-size: cover !important;
      background-repeat: no-repeat !important;

      &.stageOne {
        background: url("../assets/stageOneBackground.png");
      }
      &.stageTwo {
        background: url("../assets/stageTwoBackground.png");
      }
      &.stageThree {
        background: url("../assets/stageThreeBackground.png");
      }

      .stageNumber {
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 50px;
        position: relative;
        top: -65%;
        font-weight: bolder;
        text-align: center;

        span {
          font-size: 200px;
        }
      }
    }

    .topShape {
      position: absolute;
      top: -50%;
      left: -20%;
      opacity: 0.5;
      mix-blend-mode: overlay;
    }
  }

  .reviewsSection {
    background-image: url("../assets/heroBackground.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;

    .reviewCard {
      overflow: hidden;
      border-radius: 20px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .topShape {
      position: absolute;
      top: -25%;
      left: -20%;
      mix-blend-mode: overlay;
    }

    .bottomShape {
      position: absolute;
      bottom: 65px;
      right: 65px;
    }
  }

  .buttonS2 {
    border-radius: 60px;
    background: conic-gradient(
      from 177deg at 47.26% 50.7%,
      #30c5ef 91.13824367523193deg,
      #f4b400 181.50532007217407deg,
      #a2bb63 257.4792766571045deg,
      #e7c700 357.94113636016846deg
    );
    cursor: pointer;
    margin-top: 50px;

    a {
      color: #fff;
      font-weight: bolder;
      text-transform: uppercase;
      font-size: 36px;
      min-width: 237px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
    }
  }
}
