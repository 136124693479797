.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  height: 700px;
}

.box {
  width: 50px;
  height: 50px;
  background: #3498db;
  animation: box-expand 1s ease-in-out infinite alternate;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color: #f4b400;
  animation: circle-rotate 1s linear infinite;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 30px solid #0f9d58;
  animation: triangle-expand 1s ease-in-out infinite alternate;
}

@keyframes box-expand {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

@keyframes circle-rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes triangle-expand {
  0% {
    width: 0;
    height: 0;
  }

  100% {
    width: 30px;
    height: 60px;
  }
}
